import Herro from "./Components/Herro/Herro";
import "./App.css";
import Programes from "./Components/Programes/Programes";
import Reasons from "./Components/Reasons/Reasons";
import Plans from "./Components/Plans/Plans";
import Testimonial from "./Components/Testimonial/Testimonial";
import Join from "./Components/Join/Join";
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <Herro />
      <Programes />
      <Reasons />
      <Plans />
      <Testimonial />
      <Join />
      <Footer />
    </div>
  );
}

export default App;
